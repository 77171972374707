<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" class="form-label-6" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['sybCodes']"
                placeholder="请选择"
                allowClear
                mode="multiple"
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['prjCodes']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
                mode="multiple"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="车牌号">
              <a-input class="form-control" v-decorator="['keyWords']" placeholder="车牌号"></a-input>
            </a-form-item>
            <a-form-item label="风险类型">
              <a-select
                class="form-control"
                v-decorator="['riskTypes']"
                placeholder="风险类型"
                :options="ruleTypeList"
                allowClear
                mode="multiple"
              ></a-select>
            </a-form-item>
            <a-form-item label="风险等级">
              <a-select
                class="form-control"
                v-decorator="['riskLevels']"
                placeholder="风险等级"
                :options="typesMap.riskLevel"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="风险原因">
              <a-select
                class="form-control"
                :options="reasonList"
                v-decorator="['reasonForReleases']"
                placeholder="风险原因"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="风险判断">
              <a-select
                class="form-control"
                v-decorator="['checkAndJudge']"
                placeholder="风险判断"
                :options="judgeList"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="复核风险判断">
              <a-select
                class="form-control"
                v-decorator="['reviewCheckAndJudge']"
                placeholder="复核风险判断"
                :options="judgeList"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="材料">
              <a-select
                class="form-control"
                :options="materialTypeList"
                v-decorator="['materialType']"
                placeholder="请选择"
                mode="multiple"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="处理状态">
              <a-select
                class="form-control"
                v-decorator="['status']"
                placeholder="处理状态"
                :options="typesMap.status"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="时间">
              <a-range-picker v-decorator="['date']" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button @click="exportExcel">
            <a-icon type="export"></a-icon>
            导出
          </a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            :scroll="{ x: 1600 }"
            size="middle"
            @change="tableChange"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal :title="modal.title" v-model="modal.visible" class="modal-scroll full-screen" destroyOnClose>
      <a-form class="flex-form form-label-8 a-form-mb-0" :form="modal.form">
        <div class="flex">
          <div class="mr-5" v-for="(item, index) in modal.data.eventImageUrl" :key="index">
            <auto-img
              :src="item"
              style="width: 300px; height: 300px"
              :preview="{ options: { initialViewIndex: index }, images: modal.data.eventImageUrl }"
            ></auto-img>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div>
            <a-form-item label="风险类别">{{ modal.data.riskTypeName }}</a-form-item>
            <a-form-item label="风险名称">{{ modal.data.riskName }}</a-form-item>
            <a-form-item label="风险等级">
              <span
                :class="{
                  'text-red-500': modal.data.riskLevelCode === '1',
                  'text-yellow-500': modal.data.riskLevelCode === '2',
                }"
              >
                {{ modal.data.riskLevelName }}
              </span>
            </a-form-item>
            <a-form-item label="区域">{{ modal.data.sybName }}</a-form-item>
            <a-form-item label="项目名称">{{ modal.data.place }}</a-form-item>
            <a-form-item label="车牌号">{{ modal.data.plateNo }}</a-form-item>
            <a-form-item label="材料类别">{{ modal.data.materialTypeName }}</a-form-item>
            <a-form-item label="业务类型">{{ modal.data.businessName }}</a-form-item>
            <a-form-item label="风险报送时间">{{ modal.data.displayTime }}</a-form-item>
          </div>
          <div>
            <div v-if="isFakeStoreRisk(modal.data)">
              <a-form-item label="登记车次(挂牌)">{{ modal.data.inOrOutNum }}</a-form-item>
              <a-form-item label="车辆进出时间">{{ modal.data.inOrOutTime }}</a-form-item>
              <a-form-item label="过磅车次">{{ modal.data.identityNum }}</a-form-item>
              <a-form-item label="过磅时间">{{ modal.data.identityTime }}</a-form-item>
            </div>
            <a-form-item label="当日风险记录">
              <div><a @click="viewRiskList(modal.data)">查看</a></div>
              <!-- <a-table
            :dataSource="modal.data.relationRiskList"
            :pagination="false"
            size="small"
            v-if="modal.data.relationRiskList.length"
          >
            <a-table-column title="风险名称" dataIndex="riskName" align="center"></a-table-column>
            <a-table-column title="风险等级" dataIndex="riskLevelName" align="center"></a-table-column>
            <a-table-column title="触发时间" dataIndex="displayTime" align="center"></a-table-column>
            <a-table-column title="材料类别" dataIndex="materialTypeName" align="center"></a-table-column>
          </a-table> -->
            </a-form-item>
            <a-form-item label="当日过车记录">
              <a @click="viewCarRecord(modal.data)">查看</a>
            </a-form-item>
            <a-form-item label="处理状态">
              <render-dom :render="() => this.renderStatus(modal.data)"></render-dom>
            </a-form-item>
          </div>
        </div>

        <!-- 处理结论，查看和复核 都可以 -->
        <div v-if="modal.type === 'CHECK' || modal.type === 'REVIEW'">
          <a-form-item label="风险判断" v-if="modal.data.checkAndJudge">{{ modal.data.checkAndJudge }}</a-form-item>
          <a-form-item label="风险原因" v-if="modal.data.reasonForRelease">
            {{ modal.data.reasonForRelease }}
          </a-form-item>
          <a-form-item label="采取措施" v-if="modal.data.takeMeasures">{{ modal.data.takeMeasures }}</a-form-item>

          <a-form-item label="现场处置照片" v-if="modal.data.livePictures"></a-form-item>
          <div class="flex mb-5 -mt-6" v-if="modal.data.livePictures">
            <auto-img
              v-for="(item, index) in modal.data.livePictures"
              :key="index"
              :src="item"
              :preview="{ options: { initialViewIndex: index }, images: modal.data.livePictures }"
              style="width: 100px; height: 100px; margin: 0 3px"
            ></auto-img>
          </div>
          <a-form-item label="风险排查结论" v-if="modal.data.processMemo">
            <div
              class="rich-text-container"
              v-html="modal.data.processMemo"
              @click="richTextClick($event, modal.data.processMemo)"
            ></div>
          </a-form-item>
          <a-form-item label="处理人员" v-if="modal.data.processUserName">{{ modal.data.processUserName }}</a-form-item>
          <a-form-item label="处理时间" v-if="modal.data.processTime">{{ modal.data.processTime }}</a-form-item>
        </div>
        <!-- 复核结论，只有查看 可以 -->
        <div v-if="modal.type === 'CHECK'">
          <a-form-item label="复核风险判断" v-if="modal.data.reviewCheckAndJudge">
            {{ modal.data.reviewCheckAndJudgeName }}
          </a-form-item>
          <a-form-item label="复核风险原因" v-if="modal.data.reviewReasonForRelease">
            {{ modal.data.reviewReasonForReleaseName }}
          </a-form-item>
          <a-form-item label="复核处置结论" v-if="modal.data.reviewTakeMeasures">
            {{ modal.data.reviewTakeMeasuresName }}
          </a-form-item>
          <a-form-item label="复核风险排查结论" v-if="modal.data.reviewMemo">
            <div
              class="rich-text-container"
              v-html="modal.data.reviewMemo"
              @click="richTextClick($event, modal.data.reviewMemo)"
            ></div>
          </a-form-item>
          <a-form-item label="复核人员" v-if="modal.data.reviewUserName">{{ modal.data.reviewUserName }}</a-form-item>
          <a-form-item label="复核时间" v-if="modal.data.reviewTime">{{ modal.data.reviewTime }}</a-form-item>
        </div>

        <div v-if="modal.type === 'DEAL'">
          <a-form-item label="风险判断">
            <a-select
              class="form-control"
              :options="judgeList"
              v-decorator="['checkAndJudge', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="风险原因">
            <a-select
              class="form-control"
              :options="reasonList"
              v-decorator="['reasonForRelease', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="处置结论">
            <a-select
              class="form-control"
              :options="measureList"
              v-decorator="['takeMeasures', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="风险排查结论">
            <RichText v-decorator="['processMemo']" placeholder="请输入" />
          </a-form-item>
        </div>

        <div v-if="modal.type === 'REVIEW'">
          <a-form-item label="复核风险判断">
            <a-select
              class="form-control"
              :options="judgeList"
              v-decorator="['reviewCheckAndJudge', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="复核风险原因">
            <a-select
              class="form-control"
              :options="reasonList"
              v-decorator="['reviewReasonForRelease', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="复核处置结论">
            <a-select
              class="form-control"
              :options="measureList"
              v-decorator="['reviewTakeMeasures', { rules: [{ required: true, message: '请选择' }] }]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="复核风险排查结论">
            <RichText v-decorator="['reviewMemo']" placeholder="请输入" />
          </a-form-item>
        </div>
      </a-form>
      <template #footer>
        <a-button type="primary" v-if="modal.type === 'CHECK'" @click="handleOk('CHECK')">确认</a-button>

        <a-button type="primary" ghost v-if="modal.type === 'DEAL'" @click="handleOk('SAVE')">保存</a-button>
        <a-button type="primary" v-if="modal.type === 'DEAL'" @click="handleOk('DEAL')">提交审核</a-button>

        <a-button type="primary" v-if="modal.type === 'REVIEW'" @click="handleOk('REVIEW')">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getRuleTypeList, getMaterialTypeList, getSybList } from '@/service/getData/aie'
import { getTypeName } from '@/utils'
import RichText from '@/components/RichText/index.vue'

import moment from 'moment'
import { download } from '@/service/file'

export default {
  components: {
    ASelectPro,
    RichText,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '风险类别',
        width: 80,
        align: 'center',
        dataIndex: 'riskTypeName',
      },
      {
        title: '(系统/人工/复核)风险等级',
        width: 180,
        align: 'center',
        customRender: (text, record) => {
          return (
            <div>
              <span
                class={{
                  'text-red-500': record.riskLevelCode === '1',
                  'text-yellow-500': record.riskLevelCode === '2',
                }}
              >
                {record.riskLevelName}
              </span>
              {record.checkAndJudge && (
                <span>
                  <span>/</span>
                  <span
                    class={{
                      'text-red-500': record.checkAndJudge === '高风险',
                      'text-yellow-500': record.checkAndJudge === '中风险',
                    }}
                  >
                    {record.checkAndJudge}
                  </span>
                </span>
              )}
              {record.reviewCheckAndJudgeName && (
                <span>
                  <span>/</span>
                  <span
                    class={{
                      'text-red-500': record.reviewCheckAndJudgeName === '高风险',
                      'text-yellow-500': record.reviewCheckAndJudgeName === '中风险',
                    }}
                  >
                    {record.reviewCheckAndJudgeName}
                  </span>
                </span>
              )}
            </div>
          )
        },
      },
      {
        title: '车牌号',
        width: 90,
        align: 'center',
        dataIndex: 'plateNo',
      },
      {
        title: '区域',
        width: 130,
        align: 'center',
        dataIndex: 'sybName',
      },

      {
        title: '项目名称',
        width: '22%',
        align: 'center',
        dataIndex: 'place',
      },
      {
        title: '项目编码',
        width: 80,
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '材料类别',
        width: 80,
        align: 'center',
        dataIndex: 'materialTypeName',
      },
      {
        title: '触发时间',
        width: '15%',
        align: 'center',
        dataIndex: 'displayTime',
        sorter: true,
        sortOrder: 'ascend',
      },
      {
        title: '推送时间',
        width: '15%',
        align: 'center',
        dataIndex: 'riskCreateTime',
      },
      {
        title: '风险处理时间',
        width: '15%',
        align: 'center',
        dataIndex: 'processTime',
      },
      {
        title: '风险处理人',
        align: 'center',
        width: 90,
        dataIndex: 'processUserName',
      },
      //
      {
        title: '处理状态',
        width: 80,
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row) => {
          return this.renderStatus(row)
        },
      },
      {
        title: '操作',
        width: 130,
        align: 'center',
        customRender: (text, record) => {
          return (
            <div class="flex justify-center gap-2">
              <a
                onClick={() => {
                  this.checkRow(record)
                }}
              >
                查看
              </a>
              {(record.status === 0 || record.status === 1) && this.isFakeStoreRisk(record) && (
                <a
                  v-action="FXSJ_EDIT"
                  onClick={() => {
                    this.dealRow(record)
                  }}
                >
                  去处理
                </a>
              )}
              {record.status === 1 && this.isFakeStoreRisk(record) && this.needReview(record) && (
                <a
                  v-action="FXSJ_REVIEW"
                  onClick={() => {
                    this.reviewRow(record)
                  }}
                >
                  去复核
                </a>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      projectList: [],
      ruleTypeList: [],
      sybList: [],
      materialTypeList: [],
      judgeList: [],
      measureList: [],
      reasonList: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        riskLevel: [
          {
            label: '高风险',
            value: '1',
          },
          {
            label: '中风险',
            value: '2',
          },
          {
            label: '低风险',
            value: '3',
          },
        ],
        online: [
          {
            label: '在线',
            value: '0',
          },
          {
            label: '离线',
            value: '1',
          },
        ],
        entrance: [
          {
            label: '进',
            value: '0',
          },
          {
            label: '出',
            value: '1',
          },
        ],
        status: [
          {
            label: '未处理',
            value: '0',
          },
          {
            label: '已处理(待复核)',
            value: '1',
          },
          {
            label: '已复核',
            value: '4',
          },
          // {
          //   label: '无需处理',
          //   value: '2',
          // },
        ],
      }
    },
  },
  methods: {
    isFakeStoreRisk(row) {
      return ['8', '9'].includes(row.riskTypeCode)
    },
    needReview(row) {
      return row.checkAndJudge === '高风险' || row.checkAndJudge === '中风险'
    },
    renderStatus(row) {
      return (
        <div>
          {row.status === 0 && <span>未处理</span>}
          {row.status === 1 && <span class="text-blue-400">已处理{this.needReview(row) && '(待复核)'}</span>}
          {row.status === 2 && <span class="text-gray-400">无需处理</span>}
          {row.status === 4 && <span class="text-green-400">已复核</span>}
        </div>
      )
    },
    getTypeName,
    getFormatedPageParams(pageNo, pageSize) {
      let params = this.getPageParams(pageNo, pageSize)
      params = this.formatTimeRangeParams(params, 'date', { format: 'YYYY-MM-DD' })
      params = this.formatParams(params, (key, value) => {
        if (key === 'materialType') {
          return { key, value: value ? value.join(',') : '' }
        }
        if (key === 'sybCodes') {
          return { key, value: value ? value.join(',') : '' }
        }
        if (key === 'prjCodes') {
          return { key, value: value ? value.join(',') : '' }
        }
        if (key === 'riskTypes') {
          return { key, value: value ? value.join(',') : '' }
        }
      })
      return params
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/aie/web/event/all', this.getFormatedPageParams(_pageNo, _pageSize))
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async loadRuleTypeList() {
      const ruleTypeList = await getRuleTypeList()
      // 去掉设备掉线

      this.ruleTypeList = ruleTypeList.filter((item) => item.value !== 7)
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    async getRowRelationRiskList(row) {
      const { projectCode, plateNo, displayTime } = row
      const prjCodes = projectCode
      const keyWords = plateNo
      const date = moment(displayTime).format('YYYY-MM-DD')
      const res = await this.$axios.post('/aie/web/event/all', {
        pageNo: 1,
        pageSize: 50,
        prjCodes,
        keyWords,
        startDate: date,
        endDate: date,
      })
      return res.data
    },
    async getRowRelationCarRecord(row) {
      const { projectCode, plateNo, displayTime } = row
      const date = moment(displayTime)
      const res = await this.$axios.post('/aie/web/truck/details', {
        pageNo: 1,
        pageSize: 50,
        projectCode,
        plateNo,
        startPassTime: date.startOf('day'),
        endPassTime: date.endOf('day'),
      })
      return res.data
    },
    checkRow(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '风险详情'
      this.modal.type = 'CHECK'
      this.setModalData(row)
    },
    dealRow(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '风险处理'
      this.modal.type = 'DEAL'
      this.setModalData(row)
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    reviewRow(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '风险复核'
      this.modal.type = 'REVIEW'
      this.setModalData(row)
      console.log(this.modal)
    },
    async setModalData(row) {
      this.modal.data = {
        ...row,
        eventImageUrl: row.eventImageUrl?.split(','),
        livePictures: row.livePictures?.split(','),
      }
    },
    async handleOk(type) {
      if (type === 'CHECK') {
        this.modal.visible = false
        return
      }
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { riskId } = this.modal.data
            const data = {
              riskId,
              ...params,
            }
            if (type === 'DEAL') {
              data.status = 1
              await this.$axios.post('/aie/web/risk/dispose/create', data)
            }
            if (type === 'SAVE') {
              await this.$axios.post('/aie/web/risk/dispose/create', data)
            }
            if (type === 'REVIEW') {
              data.status = 4
              await this.$axios.post('/aie/web/risk/review/create', data)
            }
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async viewRiskList(row) {
      const { projectCode, displayTime, plateNo } = row
      const params = {
        prjCodes: projectCode,
        passDate: moment(displayTime).format('YYYY-MM-DD'),
        plateNo,
      }
      let paramsString = ''
      for (const i in params) {
        paramsString += `${i}=${params[i]}&`
      }
      window.open(`/b/m/aie/risk/riskList?${paramsString}`)
    },
    async viewCarRecord(row) {
      const { projectCode, displayTime, plateNo } = row
      const params = {
        projectCode,
        passDate: moment(displayTime).format('YYYY-MM-DD'),
        plateNo,
        noInitial: 1,
      }
      let paramsString = ''
      for (const i in params) {
        paramsString += `${i}=${params[i]}&`
      }
      window.open(`/b/m/aie/carRecord/carRecordList?${paramsString}`)
    },
    async loadMaterialTypeList() {
      this.materialTypeList = await getMaterialTypeList()
    },
    async loadJudgeList() {
      const res = await this.$axios.post('/aie/web/risk/dispose/heckAndJudge')
      this.judgeList = res.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
        }
      })
    },
    async loadMeasureList() {
      const res = await this.$axios.post('/aie/web/risk/dispose/takeMeasures')
      this.measureList = res.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
        }
      })
    },
    async loadReasonList() {
      const res = await this.$axios.post('/aie/web/risk/dispose/reasonForRelease')
      this.reasonList = res.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
        }
      })
    },
    getImgs(text) {
      // 匹配图片（g表示匹配所有结果i表示区分大小写）
      const imgReg = /<img.*?(?:>|\/>)/gi
      // 匹配src属性
      const srcReg = /src=['"]?([^'"]*)['"]?/i
      const arr = text.match(imgReg)
      const res = []
      for (let i = 0; i < arr.length; i++) {
        const src = arr[i].match(srcReg)
        // 获取图片地址
        if (src[1]) {
          res.push(src[1])
        }
      }
      return res
    },
    richTextClick(e, text) {
      if (e.target.tagName === 'IMG') {
        const imgs = this.getImgs(text)
        this.$viewerApi({
          options: {
            initialViewIndex: imgs.findIndex((i) => i === e.target.src),
          },
          images: imgs,
        })
      }
    },

    async exportExcel() {
      download('/aie/web/event/all/export', this.getFormatedPageParams(this.pageNo, this.pageSize))
    },
  },
  async mounted() {
    this.loadRuleTypeList()
    await Promise.all([this.loadProjectList(), this.loadSybList()])
    const { prjCodes, passDate, plateNo, riskTypes, action } = this.$route.query
    if (prjCodes) {
      this.form.setFieldsValue({
        prjCodes: prjCodes.split(','),
      })
    }
    if (passDate) {
      this.form.setFieldsValue({
        date: [moment(passDate), moment(passDate)],
      })
    }
    if (plateNo) {
      this.form.setFieldsValue({
        keyWords: plateNo,
      })
    }
    if (riskTypes) {
      this.form.setFieldsValue({
        riskTypes: riskTypes.split(',').map((i) => Number(i)),
      })
    }
    this.loadMaterialTypeList()
    this.loadJudgeList()
    this.loadMeasureList()
    this.loadReasonList()
    await this.doQuery()
    if (action) {
      const record = this.dataSource[0]
      if (record) {
        if (action === 'CHECK') {
          this.checkRow(record)
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
::v-deep {
  .rich-text-container {
    max-height: 600px;
    overflow: auto;
    border: 1px solid #ccc;
    padding: 0 10px;
    img {
      cursor: zoom-in;
    }
  }
  .ant-form-item-control-wrapper {
    overflow: hidden;
  }
}
</style>
